import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="wrapper" style={{ marginTop: 100, textAlign: 'center' }}>
      <h1>404 不存在的页面</h1>
      <p>抱歉，该页面不存在:)</p>
    </div>
  </Layout>
)

export default NotFoundPage
